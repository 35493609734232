<template>
	<div>
		<div class="d-flex justify-space-between align-center mb-2 mt-6">
			<div class="input-wrap">
				<h5 class="mb-2">Anulaciones</h5>
			</div>
			<div class="action-btn-wrap"></div>
		</div>
		<v-card color="secondary" class="pa-3">
			<emb-data-table :resource="resource" :columns="columns">
				<tr slot="heading">
					<th class="text-right">N°</th>
					<th class="text-center">F.Emisión</th>
					<th class="text-center">F.E.Comprobante</th>
					<th>Identificador</th>
					<th class="text-center">Ticket</th>
					<th class="text-center">Estado</th>
					<th class="text-center">Descargas</th>
					<th class="text-right">Acciones</th>
				</tr>
				<tr
					slot-scope="{ index, row }"
					:class="{
						'error--text': row.state_type_id === '05',
						'warning--text': row.state_type_id === '03',
					}"
				>
					<td class="text-right">{{ index }}</td>
					<td class="text-center">{{ row.date_of_issue }}</td>
					<td class="text-center">{{ row.date_of_reference }}</td>
					<td>{{ row.identifier }}</td>
					<td class="text-center">{{ row.ticket }}</td>
					<td class="text-center">
						<v-chip
							:color="row.state_type_id == '05' ? 'error' : 'warning'"
							text-color="white"
							x-small
						>
							{{ row.state_type_description }}
						</v-chip>
					</td>
					<td class="text-center pl-0 pr-1">
						<v-btn
							color="success"
							class="mr-1"
							x-small
							dark
							@click.prevent="clickDownload(row.download_xml)"
							v-if="row.has_xml"
						>
							XML
						</v-btn>

						<v-btn
							color="info"
							class="ma-1"
							x-small
							dark
							@click.prevent="clickDownload(row.download_cdr)"
							v-if="row.has_cdr"
						>
							CDR
						</v-btn>
					</td>
					<td class="text-right">
						<v-btn
							color="info"
							class="ma-1"
							dark
							x-small
							@click.prevent="clickTicket(row.type, row.id)"
							v-if="row.btn_ticket"
							v-on="on"
						>
							Consultar
						</v-btn>

						<v-btn
							color="error"
							dark
							x-small
							v-on="on"
							@click.prevent="clickDelete(row.type, row.id)"
							v-if="row.btn_ticket"
						>
							Eliminar
						</v-btn>
					</td>
				</tr>
			</emb-data-table>
		</v-card>
	</div>
</template>

<script>
import { deletable } from 'Mixins/deletable';

export default {
	mixins: [deletable],
	data() {
		return {
			resource: 'voided',
			showDialog: false,
			records: [],
			columns: {
				date_of_issue: 'Fecha de emisión',
			},
		};
	},
	methods: {
		clickTicket(type, id) {
			this.$http
				.get(`/${type}/status/${id}`)
				.then((response) => {
					if (response.data.success) {
						this.$message.success(response.data.message);
						this.$eventHub.$emit('reloadData', this.resource);
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch((error) => {
					if (error.response.status === 422) {
						this.errors = error.response.data.errors;
					} else {
						this.$message.error(error.response.data.message);
					}
				});
		},
		clickDelete(type, id) {
			this.destroy(`/${type}/${id}`).then(() =>
				this.$eventHub.$emit('reloadData', this.resource)
			);
		},
		clickDownload(download) {
			window.open(download, '_blank');
		},
	},
};
</script>
